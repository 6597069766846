<template>
  <div class="main-page-wrapper">
    <FeatureOne />
  </div>
</template>

<script>
import FeatureOne from "./FeatureOne.vue";

export default {
  name: "HomeMain",
  components: {
    FeatureOne,
  },
};
</script>