<template>
  <div class="fancy-text-block-seven mt-180 md-mt-100">
    <div class="bg-wrapper">
      <img src="../../assets/images/shape/29.svg" alt="" class="shapes shape-one">
      <img src="../../assets/images/shape/30.svg" alt="" class="shapes shape-two">
      <img src="../../assets/images/shape/31.svg" alt="" class="shapes shape-three">
      <img src="../../assets/images/shape/32.svg" alt="" class="shapes shape-four">
      <img src="../../assets/images/shape/33.svg" alt="" class="shapes shape-five">
      <div class="container">
        <span class="name font-effect-emboss">PORADNIA STOMATOLOGII</span>
        <div class="row">
          <div class="col-lg-5 col-md-6 col-sm-10 m-auto" data-aos="fade-right" data-aos-duration="1200">
            <div class="img-holder mt-80">
              <img src="../../assets/images/logos_/white/pexels-karolina-grabowska-6627426_dentistry.png" alt="">
              <img src="../../assets/images/shape/34.svg" alt="" class="shapes shape-six">
            </div>
          </div>
          <div class="col-xl-6 col-lg-7 ms-auto" data-aos="fade-left" data-aos-duration="1200">
            <div class="quote-wrapper pt-60">
              <!-- <img src="../../assets/images/icon/26.svg" alt="" class="icon"> -->
              <blockquote class="font-rubik" style="
              background-color:tomato;
              border-radius: 15px 50px;
              text-align: center;">
                <h6>lek. stom.</h6>Bogusława SMREK
                <h6>Spec. Stomatologii Ogólnej</h6>
              </blockquote>
              <h6 class="mt-30"></h6>
              <h6 class="mt-20"></h6>

              <blockquote class="font-rubik" style="
              background-color:tomato;
              border-radius: 15px 50px;
              text-align: center;">
                <h6>lek. stom.</h6>Tomasz MAJCHEREK
                <h6>Spec. Stomatologii Ogólnej</h6>
              </blockquote>
              <h6 class="mt-30"></h6>
              <h6 class="mt-20"></h6>

              <blockquote class="font-rubik" style="
              background-color:tomato;
              border-radius: 15px 50px;
              text-align: center;">
                <h6>lek. stom.</h6>Marek GRABOWSKI
                <h6>Spec. Ortodoncji</h6>
              </blockquote>
              <h6 class="mt-30"></h6>
              <h6 class="mt-20"></h6>
              <!-- <router-link to="/about-us-cs" class="theme-btn-two mt-45 md-mt-30">Learn More</router-link> -->
            </div> <!-- /.quote-wrapper -->
          </div>
        </div>
      </div>
    </div> <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: 'Stomatologii-main'
}
</script>