<template>
  <footer :class="`theme-footer-seven ${dark_bg ? 'dark-bg pt-120 md-pt-100' : 'mt-120 md-mt-100'}`" id="footer">
    <div class="lg-container">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-2 mb-40" data-aos="fade-up" data-aos-duration="1200">
            <div v-if="!dark_bg" class="logo"><router-link to="/">
              <img src="@/assets/images/logo/deski_06.svg" alt=""></router-link>
             </div>
            <div v-else class="logo"><router-link to="/">
              <img src="@/assets/images/logo/deski_08.svg" alt=""></router-link>
             </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-40" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
            <h5 class="title">Dane firmy :</h5>
            <ul class="footer-list">
              <li>Spółdzielnia Pracy "ESKULAP" Zespół Gabinetów Lekarzy Specjalistów</li>
              <li>32-600 Oświęcim</li>
              <li>Marii Skłodowskiej-Curie 7 /2</li>
              <li>NIP: 5492466065</li>
            </ul>
          </div>
          <div class="col-lg-2 col-md-6 mb-40" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
            <!-- <h5 class="title">Linki</h5>
            <ul class="footer-list">
              <li><a href="#">Home</a></li>
              <li><a href="#pricing">Nasze Poradnie</a></li>
              <li><a href="#footer">Kontakt</a></li>
            </ul> -->
          </div>
          <div class="col-xl-4 col-lg-5 mb-40" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
            <div class="newsletter">
              <iframe class="newsletter" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d538.7746901280198!2d19.24175373138052!3d50.032741968113335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471695089511ac01%3A0x2b79dfa8618d38cc!2sEskulap.%20Sp%C3%B3%C5%82dzielnia%20Lekarsko%20-%20Stomatologiczna!5e0!3m2!1spl!2spl!4v1673030860460!5m2!1spl!2spl" width="350" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div> 
          </div>
        </div>
      </div>

      <div class="container">
        <div class="bottom-footer">
          <div class="row">
            <div class="col-lg-4 order-lg-1 mb-20">
              <ul class="d-flex justify-content-center justify-content-lg-start footer-nav">
                <!-- <li><a href="#">Privacy & Terms.</a></li> -->
               
              </ul>
            </div>
            <div class="col-lg-4 order-lg-3 mb-20">
              <ul class="d-flex justify-content-center justify-content-lg-end social-icon">
                <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                <!-- <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li> -->
                <!-- <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li> -->
              </ul>
            </div>
            <div class="col-lg-4 order-lg-2 mb-20">
              <a href="https://www.hash.com.pl" class="copyright text-center">Copyright @2023 HASH s.c.</a>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- /.lg-container -->

  </footer>
</template>

<script>
export default {
  name: 'FooterSeven',
  props:{
    dark_btn:Boolean,
    dark_bg:Boolean,
  },
  methods: {
    onSubmit() {
      console.log('Submitted');
    },
  },
}
</script>