<template>
  <!-- <container> -->
    <Header />
    <router-view/>
    <Footer />
  <!-- </container> -->
</template>

<script setup>
import { onMounted } from "vue";
import AOS from "aos";
import Footer from '@/components/Home/Footer.vue';
import Header from '@/components/common/Headers/HeaderThree.vue';

onMounted(() => {
  AOS.init({
    duration: 120,
  });
});
</script>

