<template>
  <div class="fancy-feature-one pb-190 md-pb-80" style="background-color: #5AA994">
    <div class="text-center">
      <div class="feature-img-area">
        <img src="../../assets/images/assets/feature-img-011.png" alt="" />
        <img src="../../assets/images/logos_/pexels-antoni-shkraba-5215024-1.jpg" alt="" class="cs-screen screen-one" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "featureOne",
};
</script>